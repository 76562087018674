import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/tsuto/webapp/pcrecommend/src/components/layout.js";
import Image from "components/image";
import TOC from "components/toc";
import SaleLoadable from "components/saleLoadable";
import Socials from "components/socials";
import SmallBox from "components/smallBox";
import AccentBox from "components/accentBox";
import BasicTable from "components/basicTable";
import { Link, graphql } from "gatsby";
export const query = graphql`
  {
    allMdx(filter: {slug: {eq: "where"}}) {
      nodes {
        headings {
          depth
          value
        }
      }
    },
    cover: file(relativePath: { eq: "cover/others/where.png"}) { ...eyecatchImg },
    akiba: file(relativePath: { eq: "where/akiba.jpg"}) { ...normalImg },
    amazon: file(relativePath: { eq: "where/amazon.png"}) { ...normalImg },
    nec: file(relativePath: { eq: "where/nec.png"}) { ...normalImg },
    selectoption: file(relativePath: { eq: "where/selectoption.png"}) { ...normalImg },
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`【元販売員が語る】パソコンはどこで買うべきかのココだけの話`}</h1>
    <Socials {...props} mdxType="Socials" />
    <Image {...props} name="cover" alt="パソコンはどこで買うべきか" mdxType="Image" />
    <p>{`パソコンの販売チャネルとして大まかにはヨドバシ、ヤマダ、ビックカメラなどの`}<em parentName="p">{`量販店`}</em>{`、Dell、Lenovo、NEC、マウスコンピュータなどのネット`}<em parentName="p">{`直販サイト`}</em>{`(ダイレクトショップ)、Amazonや楽天などの`}<em parentName="p">{`ECモール`}</em>{`がある。`}</p>
    <p>{`大量仕入れの量販店、中間マージンが無い直販サイト、システム費用が不要なECモールとそれぞれが強みを持っているため実際にどこがお買い得かは分かりづらい。`}</p>
    <p>{`そこで、パソコンをどこで買うべきかを元ヨドバシカメラで働いていた管理人が中立の立場でココだけの話として述べる。なお、中古の場合は、`}<Link to="/used-pc/" mdxType="Link">{`中古パソコンはどこで買うべきか`}</Link>{`を参考にしてほしい。`}</p>
    <TOC {...props} mdxType="TOC" />
    <h2 {...{
      "id": "どこで買うのが一番お買い得か？",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%81%A9%E3%81%93%E3%81%A7%E8%B2%B7%E3%81%86%E3%81%AE%E3%81%8C%E4%B8%80%E7%95%AA%E3%81%8A%E8%B2%B7%E3%81%84%E5%BE%97%E3%81%8B%EF%BC%9F",
        "aria-label": "どこで買うのが一番お買い得か？ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`どこで買うのが一番お買い得か？`}</h2>
    <p>{`基本的に町の電気屋と楽天が高いということを除けば、どの販売チャネルが安いのかは一概には言えない。あるものは量販店が安いし、あるものはECモールや直販サイトが安い。`}</p>
    <p>{`ともあれメーカーによって販売チャネルごとの価格には傾向があるのでそのあたりを論じていきたい。`}</p>
    <h3 {...{
      "id": "量販店",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E9%87%8F%E8%B2%A9%E5%BA%97",
        "aria-label": "量販店 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`量販店`}</h3>
    <Image {...props} name="akiba" alt="量販店" mdxType="Image" />
    <p>{`量販店は人件費や土地代がかかるため、それが費用に転嫁されて商品の値段が高くなってしまうという意見がある。`}</p>
    <p>{`その通りであるが、同時に大量に商品を売り切る能力を持っているため仕入れ値は安く、必ずしも割高であるとは言えない。`}</p>
    <p>{`メーカーとしてはAppleはブランド戦略として全てのショップで値引き無しであるが、`}<strong parentName="p"><em parentName="strong">{`ポイントが5%つくためMacbookは量販店での購入が最もお得である`}</em></strong>{`。`}</p>
    <p>{`また、NEC、富士通、Dynabook(旧東芝)の御三家様は特に量販店への依存が高いために力関係が弱く、`}<strong parentName="p"><em parentName="strong">{`直販サイトで量販店よりも安く売ってはならないという暗黙の了解`}</em></strong>{`がある。`}</p>
    <p>{`直販サイトサイドも怒られない範囲でセールを行っているが、これら３社はポイントを含めると量販店の方が安くなる可能性が高い。`}</p>
    <h3 {...{
      "id": "ECモール",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#EC%E3%83%A2%E3%83%BC%E3%83%AB",
        "aria-label": "ECモール permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ECモール`}</h3>
    <Image {...props} name="amazon" alt="ECモール" mdxType="Image" />
    <p>{`楽天は町の電気屋と同様個人商店が多く、またポイント還元率を捻出するために価格が高いものが多い。`}</p>
    <p>{`楽天でパソコンを買う人も少ないことから量を裁くことができない点も割高になるポイントであり、余程楽天に依存している人以外は調べる必要も無いだろう。`}</p>
    <p>{`また、Amazonはそれなりに数量が出るため多くのメーカーが販売を行っているが、`}<strong parentName="p"><em parentName="strong">{`販売手数料として`}<a parentName="em" {...{
            "href": "https://sell.amazon.co.jp/pricing#referral-fees",
            "target": "_blank",
            "rel": "nofollow noopener"
          }}>{`8%のマージン`}</a>{`を取られてしまう`}</em></strong>{`ので、できれば直販サイトで売りたいが本音であろう。`}</p>
    <h3 {...{
      "id": "直販サイト",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E7%9B%B4%E8%B2%A9%E3%82%B5%E3%82%A4%E3%83%88",
        "aria-label": "直販サイト permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`直販サイト`}</h3>
    <Image {...props} name="nec" alt="直販サイト" mdxType="Image" />
    <p>{`NEC、富士通、Dynabookが量販店よりも不利になることが多いことは述べたが、Dell、Lenovo、HPなど海外メーカーやマウスコンピュータ、パソコン工房などのBTOメーカーは直販サイトを主な販売チャネルとしているため量販店への依存度は少ない。`}</p>
    <p>{`ECモールへの依存度も同様に少なく、店舗側の在庫処分やECモールのセールなど
一部の条件を除けば`}<strong parentName="p"><em parentName="strong">{`多くのメーカーでは直販サイトが最も安くなる可能性は高い`}</em></strong>{`。製品も工場直送だったりする。`}</p>
    <p>{`マイクロソフトに関してはアップル同様あまり値引きがされないが、直販サイトでは60日間の返品保障が認められるなどメリットは大きいため、総合的に判断するべきである。`}</p>
    <h2 {...{
      "id": "量販店で注意すべきこと",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E9%87%8F%E8%B2%A9%E5%BA%97%E3%81%A7%E6%B3%A8%E6%84%8F%E3%81%99%E3%81%B9%E3%81%8D%E3%81%93%E3%81%A8",
        "aria-label": "量販店で注意すべきこと permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`量販店で注意すべきこと`}</h2>
    <p>{`量販店は実際の商品に触ることができ、そこから得られる情報は大きい。しかしパソコンでまず決めるべきはスペックであり、外見ではない。`}</p>
    <p>{`また、視覚においてもディスプレイは失敗する可能性が高い要素なので注意をしておく。`}</p>
    <h3 {...{
      "id": "情弱狩りの聖地であることは肝に銘ずべき",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E6%83%85%E5%BC%B1%E7%8B%A9%E3%82%8A%E3%81%AE%E8%81%96%E5%9C%B0%E3%81%A7%E3%81%82%E3%82%8B%E3%81%93%E3%81%A8%E3%81%AF%E8%82%9D%E3%81%AB%E9%8A%98%E3%81%9A%E3%81%B9%E3%81%8D",
        "aria-label": "情弱狩りの聖地であることは肝に銘ずべき permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`情弱狩りの聖地であることは肝に銘ずべき`}</h3>
    <p>{`量販店の販売員は常に客のことを考えて動いている訳ではなく、戦略的な販売商品であったり、在庫やリベート(販売報奨金)の有無も考えて行動している。`}</p>
    <p>{`よって`}<strong parentName="p"><em parentName="strong">{`客が買いたい商品と販売員が売りたい商品は異なってくる`}</em></strong>{`。`}</p>
    <p>{`また、販売目標があることと、購入したもののOfficeが入っていなかったなどのクレームを回避するため、できるだけ多くのソフトウェアが入った失敗の少ない商品を勧めたいという事情もある。`}</p>
    <p>{`このようなモチベーションが販売員には働くため、`}<strong parentName="p"><em parentName="strong">{`おすすめは何かと聞かれると販売員にとってのおすすめが返ってくる可能性も高い`}</em></strong>{`。`}</p>
    <p>{`以前テレビ売り場にいた時、ある期間東芝のREGZAだけリベートがあったため、どのメーカーがおすすめですか？と聞かれたときに「東芝ですね～」と社員は答えていたものである。`}</p>
    <p>{`パソコン自体に関しては販売員は詳しいため、機能やパーツの質問はしても良いが、おすすめのパソコンは最終的に自分で探すことをおすすめする。`}</p>
    <h3 {...{
      "id": "ディスプレイの輝度に注意",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%87%E3%82%A3%E3%82%B9%E3%83%97%E3%83%AC%E3%82%A4%E3%81%AE%E8%BC%9D%E5%BA%A6%E3%81%AB%E6%B3%A8%E6%84%8F",
        "aria-label": "ディスプレイの輝度に注意 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ディスプレイの輝度に注意`}</h3>
    <p>{`量販店のテレビ売り場では、ディスプレイが明るい方が見栄えがするため、全てのテレビの輝度を最大まで上げている。`}</p>
    <p>{`ところが実際に家庭で輝度を最大まで上げると明るすぎるため、大抵の人は輝度を落としてテレビを見ている。すなわち明るさが商品の決定要素になっているものの、その要素を購入後活用していないのである。`}</p>
    <p>{`同じことがパソコンにも言える。やや`}<strong parentName="p"><em parentName="strong">{`光沢があるディスプレイのノートパソコンが量販店では人気が高いが、実際に使うと目がちかちかして`}</em></strong>{`こんなはずではなかったとなることがある。`}</p>
    <h3 {...{
      "id": "値下げ交渉は可能",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E5%80%A4%E4%B8%8B%E3%81%92%E4%BA%A4%E6%B8%89%E3%81%AF%E5%8F%AF%E8%83%BD",
        "aria-label": "値下げ交渉は可能 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`値下げ交渉は可能`}</h3>
    <p>{`Appleは無理であり、Microsoftも恐らく厳しいが、その他のメーカーに関しては値下げ交渉は商品によりけりだが可能である。`}</p>
    <p>{`ヨドバシの話であるが、ある一定以上の値下げはフロアのマネージャーが権限を握っているので、メーカー販売員ではなくやや高齢の店員に話を掛けるのがコツである。`}</p>
    <p>{`すると店員は、「マネージャー、４番です」と隠語で語りかけ、マネージャーが値引き幅を示すといった感じである。若手の店員だとマネージャーに尋ねるのを躊躇する可能性があるのでできれば避けたい。私も聞くの嫌いだった。`}</p>
    <p>{`ヤマダやビックカメラは話を聞く限りでは一般社員でも割と大幅な値引き幅を持っていた気がする。`}</p>
    <p><strong parentName="p"><em parentName="strong">{`交渉材料としてはいくつか店舗を周り価格調査をした上で、買いたい価格を提示するのが鉄板`}</em></strong>{`である。場所は池袋のヤマダとビックカメラが一番値引きがしやすいし、店員も対応に慣れている。`}</p>
    <p>{`１円でも安く買いたいという気力があるならやってみよう。恐らくネットより安く買えるはずである。地方はまあ回る店舗が近くにないので厳しいか。。`}</p>
    <h2 {...{
      "id": "直販サイトは一般的におすすめできる",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E7%9B%B4%E8%B2%A9%E3%82%B5%E3%82%A4%E3%83%88%E3%81%AF%E4%B8%80%E8%88%AC%E7%9A%84%E3%81%AB%E3%81%8A%E3%81%99%E3%81%99%E3%82%81%E3%81%A7%E3%81%8D%E3%82%8B",
        "aria-label": "直販サイトは一般的におすすめできる permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`直販サイトは一般的におすすめできる`}</h2>
    <p>{`次にネットでの直販サイト、前述の通りAppleは量販店の方がポイント分お得であり、NEC、富士通、Dynabookも量販店との力関係により安くなりにくい。`}</p>
    <p>{`しかし保証面が有利であったり、何よりカスタマイズ性が高いことが直販サイトの良いところである。そのあたりを説明する。`}</p>
    <h3 {...{
      "id": "自分に合ったスペックにカスタマイズできる",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E8%87%AA%E5%88%86%E3%81%AB%E5%90%88%E3%81%A3%E3%81%9F%E3%82%B9%E3%83%9A%E3%83%83%E3%82%AF%E3%81%AB%E3%82%AB%E3%82%B9%E3%82%BF%E3%83%9E%E3%82%A4%E3%82%BA%E3%81%A7%E3%81%8D%E3%82%8B",
        "aria-label": "自分に合ったスペックにカスタマイズできる permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`自分に合ったスペックにカスタマイズできる`}</h3>
    <p>{`量販店は、購入したものをすぐに持ち帰ることはできるものの、購入できるパソコンの選択肢は多くはない。`}</p>
    <p>{`ドンピシャでハマるものがあれば良いが、ストレージの容量が過剰だったり、CPU能力がやや低いなど、１つや２つは妥協しなければならないポイントが出てくることが普通である。`}</p>
    <p>{`これに対して直販サイトは、`}<strong parentName="p"><em parentName="strong">{`自分にあったパソコンのスペックに合わせてパソコンを購入することが可能`}</em></strong>{`である。`}</p>
    <p>{`マウスコンピュータやパソコン工房といったBTO(Build To Order:受注生産の意味)パソコンサイトでは、特に細かいチューニングが可能で、CPUやメモリ、ストレージなど基本パーツは当然のこととして、CPUグリスや電源まで選択可能である。`}</p>
    <Image {...props} name="selectoption" caption="マニアックなBTOオプション" mdxType="Image" />
    <p>{`まあマニアックなところは基本標準で良いが、とことんまでこだわれるという事が分かるだろう。`}</p>
    <h3 {...{
      "id": "選択肢の幅が広い",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E9%81%B8%E6%8A%9E%E8%82%A2%E3%81%AE%E5%B9%85%E3%81%8C%E5%BA%83%E3%81%84",
        "aria-label": "選択肢の幅が広い permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`選択肢の幅が広い`}</h3>
    <p>{`直販サイトはその`}<strong parentName="p"><em parentName="strong">{`メーカーが扱っている全てのモデルを購入することが可能`}</em></strong>{`である。`}</p>
    <p>{`また、Web限定のオリジナルモデルや、カラーバリエーションが用意されていることも多く、選択肢の幅が非常に広いといえる。`}</p>
    <h3 {...{
      "id": "延長保証に強い",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E5%BB%B6%E9%95%B7%E4%BF%9D%E8%A8%BC%E3%81%AB%E5%BC%B7%E3%81%84",
        "aria-label": "延長保証に強い permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`延長保証に強い`}</h3>
    <p>{`一般的なメーカー保証は１年のみであるが、`}<strong parentName="p"><em parentName="strong">{`富士通やVAIOのWebサイトから購入すれば標準で３年保証`}</em></strong>{`、あるいは他のメーカーも様々な保証サービスを打ち出している。`}</p>
    <p>{`これには自社製品のことは自社が一番良く分かっているため、きめ細かい保証制度設計が可能ということもあるし、量販店からでは得られにくい自社製品のデータを蓄積して次の開発に役立てていきたいという思惑もあるだろう。`}</p>
    <p>{`もっとも全てのメーカーが顧客重視で保証サービスを展開しているのかは分からないが。ちなみにヨドバシでは５年保証は利益率が高いのでしっかり取るようにと言われていた。`}</p>
    <h2 {...{
      "id": "ECモールは玄人向け",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#EC%E3%83%A2%E3%83%BC%E3%83%AB%E3%81%AF%E7%8E%84%E4%BA%BA%E5%90%91%E3%81%91",
        "aria-label": "ECモールは玄人向け permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ECモールは玄人向け`}</h2>
    <p>{`Amazonでパソコンを購入するのはかなり難しい。カスタマイズ性に乏しいため、自分にあった製品を見つけるのは難しいし、販売元が信用できる保証がない、PCの発売時期が不明なものが多いなど`}<strong parentName="p"><em parentName="strong">{`検索力と目利きがかなり求められる`}</em></strong>{`。`}</p>
    <p>{`頑張れば直販サイトよりも安い価格のパソコンが見つかることもあるが、やはり選びにくいので直販サイトで購入するのが良いだろう。`}</p>
    <p>{`メリットとしてはワンクリックでポチっと買えるため購入時に手間がかからないことぐらいである。`}</p>
    <h2 {...{
      "id": "まとめ、パソコンはどこで買うのがおすすめか？",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%81%BE%E3%81%A8%E3%82%81%E3%80%81%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%81%AF%E3%81%A9%E3%81%93%E3%81%A7%E8%B2%B7%E3%81%86%E3%81%AE%E3%81%8C%E3%81%8A%E3%81%99%E3%81%99%E3%82%81%E3%81%8B%EF%BC%9F",
        "aria-label": "まとめ、パソコンはどこで買うのがおすすめか？ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`まとめ、パソコンはどこで買うのがおすすめか？`}</h2>
    <p>{`メーカーごとにどこで購入すべきかをまとめた表が下である。`}</p>
    <BasicTable mdxType="BasicTable">
  <thead>
    <tr>
      <th>販売チャネル</th>
      <th>メーカー</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>量販店</td>
      <td>Apple、Microsoft</td>
    </tr>
    <tr>
      <td>量販店か直販サイト</td>
      <td>富士通、NEC、Dynabook</td>
    </tr>
    <tr>
      <td>直販サイト</td>
      <td>Dell、Lenovo、HP、マウスコンピュータ、パソコン工房などその他</td>
    </tr>
  </tbody>
    </BasicTable>
    <p>{`まずAppleはどこで買っても値段が同じのため、ポイントがつく量販店での買い物がおすすめである。また、Microsoftも量販店がポイントを含めて安くなる可能性が高い。`}</p>
    <p>{`Microsoftは直販サイトで60日間は返品自由であるため、リスクヘッジという意味で直販サイトも有効な選択肢ではある。製品に自信が持てないなら公式サイトもありだろう。ただ私なら量販店を選ぶと思うので量販店とした。稀にセールはやるのでチェックはしても良いかも。。`}</p>
    <p>{`次にNEC、富士通、東芝も量販店の方が直販サイトよりもポイントを含めると安くなる可能性は高く、値引き交渉を巧みに行えばほぼ量販店の方が安く買えるだろう。`}</p>
    <p>{`しかしながら直販サイトはカスタマイズ性が高く全モデルが揃うため、自分にとって不要な部分を削ぎ落とせるならば、直販サイトの方が有利な側面も多い。私なら恐らく価格交渉が面倒かつ好きなモデルが置いてあるか分からないので直販で買うと思われる。`}</p>
    <p>{`最後にDell、Lenovo、HP、マウスコンピュータなどその他のメーカーは量販店の圧力も大きくないため直販サイトが価格の面でもカスタマイズの面でもおすすめである。`}</p>
    <p>{`現在セール中の一覧は下記でまとめているので、ネットでの通常の販売価格よりも安く買いたい場合は参考にして欲しい。`}</p>
    <SaleLoadable title="現在のパソコンセール・お買い得情報" mdxType="SaleLoadable" /><br />
    <p>{`また、`}<Link to="/pcmaker/" mdxType="Link">{`パソコンメーカー一覧`}</Link>{`で各メーカーの特徴と直販サイトへのリンクがあるので参考にして欲しい。`}</p>
    <p>{`最後に自分に合ったスペックが分からないならば、当サイトの`}<Link to="/" mdxType="Link">{`トップページ`}</Link>{`へ行き、メーカーやスペックの推薦を是非受けてみて欲しい。`}</p>
    <p>{`それでは楽しいパソコンライフを！`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      